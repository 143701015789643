import React, {
  forwardRef,
  useRef,
  useState,
  useEffect,
  useImperativeHandle,
} from 'react';
import PropTypes from 'prop-types';
import StyledDetailsLandSpecs from './DetailsLandSpecs.Style';
import { formatPhoneNumber } from '../../utils';
import { states } from '../../states';
import CheckBox from '../CheckBox/CheckBox';

const DetailsLandSpecs = ({
  StockNumber,
  BrokerageName,
  BrokeragePhone,
  BrokerageEmail,
  setAddress,
  setCity,
  setState,
  setZip,
  setAcres,
  setBrokerageName,
  setBrokeragePhone,
  setBrokerageEmail,
  setInventoryDescription,
  Address1,
  City,
  StateProvince,
  PostalCode,
  Acres,
  InventoryDescription,
  acresRef,
  addrRef,
  brokerageNameRef,
  brokerageContactRef,
}) => {
  const descriptionRef = useRef(null);
  const descriptionMaxLength = 500;

  const [isChecked, setIsChecked] = useState(true);

  const handleCheckBoxChange = event => {
    if (InventoryDescription === null || InventoryDescription === '') {
      setIsChecked(!isChecked);
    } else {
      setInventoryDescription('');
      setIsChecked(!isChecked);
    }
  };

  useEffect(() => {
    if (InventoryDescription !== null) {
      setIsChecked(false);
    }
  }, []);

  const characterCount =
    descriptionRef.current &&
    `${descriptionMaxLength -
      descriptionRef.current.value
        .length}/${descriptionMaxLength} characters remaining`;

  return (
    <StyledDetailsLandSpecs>
      <h2>Land Specs</h2>

      <div className="land-card">
        <div className="land-info">
          <div className="name-wrapper">
            <label htmlFor="Address1">
              <span className="label">Address</span>
              <input
                id="Address1"
                type="text"
                value={Address1}
                maxLength={254}
                onChange={event => setAddress(event.currentTarget.value)}
                ref={addrRef}
              />
            </label>
          </div>
          <div className="name-wrapper">
            <label htmlFor="City">
              <span className="label">City</span>
              <input
                id="City"
                type="text"
                maxLength={254}
                value={City}
                onChange={event => setCity(event.currentTarget.value)}
              />
            </label>
          </div>
          <div className="name-wrapper">
            <label htmlFor="StateProvince">
              <span className="label">State</span>
              <select
                id="StateProvince"
                value={StateProvince}
                onChange={event => setState(event.currentTarget.value)}
              >
                {states.map((item, index) => (
                  <option
                    key={`state-${index}`}
                    value={item.value || item.text || item}
                  >
                    {item.text || item}
                  </option>
                ))}
              </select>
            </label>
          </div>
          <div className="name-wrapper">
            <label htmlFor="PostalCode">
              <span className="label">Zip</span>
              <input
                id="PostalCode"
                type="text"
                maxLength="5"
                value={PostalCode}
                onChange={event => setZip(event.currentTarget.value)}
              />
            </label>
          </div>
          <div className="name-wrapper">
            <label htmlFor="Acres">
              <span className="label">Acres</span>
              <input
                id="Acres"
                type="text"
                value={Acres === 0 ? '' : Acres}
                onChange={event => setAcres(event.currentTarget.value)}
                ref={acresRef}
              />
            </label>
          </div>
          <div className="name-wrapper">
            <label htmlFor="BrokerageName">
              <span className="label">Brokerage Name*</span>
              <input
                id="BrokerageName"
                type="text"
                value={BrokerageName}
                onChange={event => setBrokerageName(event.currentTarget.value)}
                ref={brokerageNameRef}
              />
            </label>
          </div>
          <div className="name-wrapper">
            <label htmlFor="BrokerageEmail">
              <span className="label">Brokerage Email*</span>
              <input
                id="BrokerageEmail"
                type="text"
                value={BrokerageEmail}
                onChange={event => setBrokerageEmail(event.currentTarget.value)}
                ref={brokerageContactRef}
              />
            </label>
          </div>
          <div className="name-wrapper">
            <label htmlFor="BrokeragePhone">
              <span className="label">Brokerage Phone*</span>
              <input
                id="BrokeragePhone"
                type="text"
                placeholder="(___)___-____"
                value={formatPhoneNumber(BrokeragePhone)}
                maxLength="10"
                onChange={event => setBrokeragePhone(event.currentTarget.value)}
              />
            </label>
          </div>
        </div>
        <div className="description-wrapper">
          <label htmlFor="InventoryDescription">
            <span className="label">Inventory Description</span>
            <span className="sub-label">
              If you enter your own description, it will display on the detail
              page in place of the default description.
            </span>
            <textarea
              name="InventoryDescription"
              maxLength="500"
              ref={descriptionRef}
              value={InventoryDescription}
              onChange={event =>
                setInventoryDescription(event.currentTarget.value)
              }
            />
          </label>
          <p className="character-count">{characterCount}</p>
          <div className="description-box-container">
            <label htmlFor="descriptionBox">
              <CheckBox
                name="descriptionBox"
                id="descriptionBox"
                checked={isChecked}
                onChange={handleCheckBoxChange}
              />
              <span className="description-label">Use default description</span>
            </label>
          </div>
        </div>
        <div className="numbers">
          <div className="number">
            <span className="label">Stock Number</span>
            {StockNumber}
          </div>
        </div>
        <p className="brokerage-disclaimer">
          <strong>*Brokerage info is only required in these states:</strong>
          <br />
          Alabama, Alaska, Arizona, Arkansas, California, Colorado, Connecticut,
          Washington D.C., Florida, Hawaii, Iowa, Kansas, Kentucky, Maryland,
          Michigan, Minnesota, Mississippi, Montana, Nebraska, Nevada, New
          Jersey, New York, Ohio, Tennessee, Utah, Washington,
          Wisconsin, West Virginia, Wyoming
        </p>
      </div>
    </StyledDetailsLandSpecs>
  );
};
DetailsLandSpecs.propTypes = {
  Name: PropTypes.string.isRequired,
  tags: PropTypes.arrayOf(PropTypes.string),
  Beds: PropTypes.number.isRequired,
  Baths: PropTypes.number.isRequired,
  SquareFeet: PropTypes.number.isRequired,
  StockNumber: PropTypes.string,
  SerialNumber: PropTypes.string,
  ModelNumber: PropTypes.string.isRequired,
  IsAvailableFloorPlan: PropTypes.bool,
  setHomeName: PropTypes.func.isRequired,
};

DetailsLandSpecs.defaultProps = {
  tags: [],
  StockNumber: '',
  SerialNumber: '',
  IsAvailableFloorPlan: true,
};

export default DetailsLandSpecs;
