import React, {
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import isEqual from 'lodash.isequal';
import imagesReducer from '../../reducers/images';
import ImageUploader from '../ImageUploader/ImageUploader';
import StyledImages from './Images.Style';
import InventoryImageCard from './InventoryImageCard';
import ModelImagesCard from './ModelImagesCard';
import NoImagesAvailable from './NoImagesAvailable';
import { qualifyUrl, reorderArray } from '../../utils';
import {
  uploadHomeImage,
  deleteImage,
  reorderImages,
  updateImage,
} from '../../Api';
import { UserContext } from '../User';
import ToastSave from '../ToastSave';
import { SAVE_TOAST_OPTIONS, ERROR_TOAST_OPTIONS } from '../../constants';
import Modal from '../Modal';
import { analyticsSendEvent } from '../../utils';

/**
 * The top level component for the home images page
 *
 * @param home
 * @param home.InventoryId
 * @param {{InventoryImagesId: number}[]} home.InventoryImages
 * @param home.ModelImages
 * @param rooms
 * @param updateHome
 * @param history
 */
const Images = ({ home, rooms, updateHome, history }) => {
  const [state, dispatch] = useReducer(imagesReducer, {
    images: [],
    isLoading: true,
    isUpdating: false,
  });
  const { images, isLoading, isUpdating } = state;
  const { activeLot } = useContext(UserContext);
  const [showModal, setShowModal] = useState(false);
  const [lastLocation, setLastLocation] = useState(null);
  const availableRooms = useMemo(() => {
    return rooms;
  }, [rooms]);

  /**
   * Updates state with a reordered array of images
   *
   * @param {int} startIndex The initial index of the card
   * @param {int} endIndex The new index of the card
   */
  const moveCard = (startIndex, endIndex) => {
    dispatch({
      type: 'SET_IMAGES',
      payload: reorderArray(images, startIndex, endIndex),
    });
  };

  /**
   * Use this function to find a gap in the sort orders of inventory images
   * That gap is where we place the model images card when rendering the image cards
   * todo this might be broken
   *
   * @param {[]} list
   */
  const calculateModelImagesSortOrder = list => {
    return list.reduce((prev, { SortOrder: next }) => {
      if (prev + 1 < next) {
        return prev;
      }
      return prev + 1;
    }, 0);
  };

  /**
   * This is used to clean up our images array for API request
   *
   * @type {[]}
   */
  const orderedImages = useMemo(() => {
    return images
      .map((image, index) => ({
        ...image,
        SortOrder: index + 1,
      }))
      .filter(image => image.InventoryImagesId);
  }, [images]);

  /**
   * This is used to keep track of our initial inventory images array.
   *
   * @type {[]}
   */
  const orderedHomeImages = useMemo(() => {
    const tempImages = [...home.InventoryImages];
    tempImages.splice(calculateModelImagesSortOrder(home.InventoryImages), 0, {
      ...home.ModelImages[0],
    });
    return tempImages
      .map((image, index) => ({
        ...image,
        SortOrder: index + 1,
      }))
      .filter(image => image.InventoryImagesId);
  }, [home.InventoryImages, home.ModelImages]);

  /**
   * Use this variable to determine if save changes button should be showed.
   *
   * @type {boolean}
   */
  const imagesChanged = useMemo(() => {
    if (orderedImages.length > 0 && orderedHomeImages.length > 0) {
      return !isEqual(orderedHomeImages, orderedImages);
    }
    return false;
  }, [orderedHomeImages, orderedImages]);

  /**
   * Optimistically updates the state of the homes array before calling
   * the API and saving updated order of images
   */
  const reorderSaveFunction = useCallback(() => {
    try {
      const request = reorderImages(
        activeLot.LotNumber,
        home.InventoryId,
        orderedImages
      );
      if (!request) {
        toast.error('Request could not be completed.', ERROR_TOAST_OPTIONS);
      } else {
        analyticsSendEvent('home_images_sort');
        // update home state with new images
        updateHome({ ...home, InventoryImages: [...orderedImages] }, true);
      }
    } catch (error) {
      toast.error('Request could not be completed.', ERROR_TOAST_OPTIONS);
    }
  }, [activeLot.LotNumber, home, orderedImages, updateHome]);

  /**
   * Optimistically updates the state of the home before calling
   * the API and saving updated image data
   *
   * @param {{InventoryImagesId: number}} image The updated image object
   */
  const saveDetailsFunction = image => {
    const newImages = [...home.InventoryImages].map(img =>
      img.InventoryImagesId === image.InventoryImagesId ? image : img
    );
    try {
      dispatch({ type: 'UPDATE_IMAGE_INIT' });
      const request = updateImage(activeLot.LotNumber, home.InventoryId, image);
      if (!request) {
        toast.error('Request could not be completed.', ERROR_TOAST_OPTIONS);
        dispatch({ type: 'UPDATE_IMAGE_FAILURE' });
      } else {
        // This update does not have to be optimistic because it doesn't affect the UI
        updateHome(
          {
            ...home,
            InventoryImages: newImages,
          },
          true
        ).then(() => {
          dispatch({ type: 'UPDATE_IMAGE_SUCCESS' });
        });
      }
    } catch (error) {
      dispatch({ type: 'UPDATE_IMAGE_FAILURE' });
      toast.error(`Save failed: ${error.message}`, ERROR_TOAST_OPTIONS);
    }
  };

  /**
   * This function creates a FormData objects and sends it in a POST request to the API.
   * After a successful request, the image is added to the images state and home's state
   *
   * @param {object} data the object containing the image data
   * @returns {Promise<void>}
   */
  const uploadFunction = async data => {
    const { file, imageTypeAcronym, caption, roomId } = data;
    const formData = new FormData();

    formData.append('file', file);
    formData.append('imageTypeAcronym', imageTypeAcronym);
    formData.append('caption', caption);
    if (roomId) {
      formData.append('roomId', roomId);
    }
    formData.append('inventoryId', home.InventoryId);
    try {
      const response = await uploadHomeImage(
        activeLot.LotNumber,
        home.InventoryId,
        formData
      ).then();

      if (response === false) {
        toast.error(
          `${file.name} could not be uploaded. API returned false`,
          ERROR_TOAST_OPTIONS
        );
      }
      const newImage = await response.json();

      if (newImage) {
        // add the new image to our images array
        dispatch({
          type: 'ADD_IMAGE_INIT',
          payload: { ...newImage, Reference: qualifyUrl(newImage.Reference) },
        });

        toast.info(`${file.name} uploaded`, {
          position: toast.POSITION.BOTTOM_RIGHT,
          closeButton: false,
        });
        // keep home's state up to date with changes
        const newImages = [
          ...home.InventoryImages,
          { ...newImage, Reference: qualifyUrl(newImage.Reference) },
        ];

        analyticsSendEvent('home_images_upload', {
          groupId: newImage.ImageType,
          groupName: newImage.ImageTypeAcronym,
          roomId: newImage.RoomId,
          roomName: newImage.RoomDescription,
        });
        updateHome(
          {
            ...home,
            InventoryImages: newImages,
          },
          true
        );

        dispatch({
          type: 'ADD_IMAGE_SUCCESS',
        });
      }
    } catch (error) {
      toast.error(
        `${file.name} could not be uploaded. Error: ${error.message}`,
        ERROR_TOAST_OPTIONS
      );
    }
  };

  /**
   * This function removes the image with the provided ID from both the local state and the home's state.
   * It also sends a DELETE request to the API and will show an error toast if something goes wrong.
   *
   * @param imageId The InventoryImagesId value of the image to delete
   * @returns {Promise<void>}
   */
  const deleteFunction = async imageId => {
    // undo toast might not be feasible
    try {
      dispatch({ type: 'DELETE_INVENTORY_IMAGE_INIT', payload: imageId });

      const deleted = await deleteImage(
        activeLot.LotNumber,
        home.InventoryId,
        imageId
      ).then();

      dispatch({ type: 'DELETE_INVENTORY_IMAGE_COMPLETED', payload: imageId });

      if (deleted) {
        dispatch({ type: 'DELETE_IMAGE_INIT', payload: imageId });

        const newImages = [...home.InventoryImages].filter(
          image => image.InventoryImagesId !== imageId
        );

        updateHome(
          {
            ...home,
            InventoryImages: newImages,
          },
          true
        );

        dispatch({ type: 'DELETE_IMAGE_SUCCESS' });
      } else {
        toast.error('Image could not be deleted', ERROR_TOAST_OPTIONS);
      }
    } catch (error) {
      toast.error('Image could not be deleted', ERROR_TOAST_OPTIONS);
    }
  };

  /**
   * Toggles modal state to what it is not
   *
   * @param {{}} location
   */
  const toggleModal = useCallback(
    location => {
      toast.dismiss();
      setShowModal(!showModal);
      setLastLocation(location);
    },
    [showModal]
  );

  /**
   * This function saves the sort order of the images before
   * forwarding users to their intended destination.
   */
  const handleConfirmNavigationClick = () => {
    reorderSaveFunction();
    setTimeout(() => {
      setShowModal(false);
      history.push(lastLocation);
    }, 0);
  };

  /**
   * This function resets the images state to it's initial value before
   * forwarding users to their intended destination.
   */
  const handleDiscardNavigationClick = () => {
    const tempImages = [...home.InventoryImages];
    if (home.ModelImages.length > 0) {
      tempImages.splice(
        calculateModelImagesSortOrder(home.InventoryImages),
        0,
        { ...home.ModelImages[0] }
      );
    }
    dispatch({
      type: 'SET_IMAGES',
      payload: tempImages,
    });
    setTimeout(() => {
      setShowModal(false);
      history.push(lastLocation);
    }, 0);
  };

  // On mount, add home images to state
  useEffect(() => {
    dispatch({
      type: 'SET_IMAGES_INIT',
    });
    const tempImages = [...home.InventoryImages];
    if (home.ModelImages.length > 0) {
      tempImages.splice(
        calculateModelImagesSortOrder(home.InventoryImages),
        0,
        { ...home.ModelImages[0] }
      );
    }
    dispatch({
      type: 'SET_IMAGES_SUCCESS',
      payload: tempImages,
    });
  }, [home.InventoryImages, home.ModelImages]);

  // Toggle the save toast when changes have been made
  useEffect(() => {
    if (!isLoading && imagesChanged && !showModal && !isUpdating) {
      if (!toast.isActive('saveToast')) {
        toast.info(
          <ToastSave saveFunction={reorderSaveFunction} />,
          SAVE_TOAST_OPTIONS
        );
      } else {
        // rewrite save function with updated images object
        toast.update('saveToast', {
          render: <ToastSave saveFunction={reorderSaveFunction} />,
        });
      }
    } else if (!imagesChanged && toast.isActive('saveToast')) {
      toast.dismiss('saveToast');
    }
    return () => {
      if (!imagesChanged && toast.isActive('saveToast')) {
        toast.dismiss('saveToast');
      }
    };
  }, [
    isLoading,
    imagesChanged,
    orderedImages,
    showModal,
    isUpdating,
    reorderSaveFunction,
  ]);

  // Block navigation if changes have been made, and prompt users to save or discard
  useEffect(() => {
    const unblock = history.block(nextLocation => {
      toggleModal(nextLocation);
      return false;
    });
    if (!imagesChanged) {
      unblock();
    }
    return () => {
      unblock();
    };
  }, [imagesChanged, history, toggleModal]);

  return (
    <StyledImages>
      {home && (
        <>
          <div className="uploader-wrapper">
            <ImageUploader
              uploadFunction={uploadFunction}
              rooms={availableRooms}
              headerText="Upload home images"
            />
          </div>
          <div className="images-wrapper">
            <h2>Home Images</h2>
            <p>
              The first image will be used on the home listing page. The first
              four images will appear on your brochure.
              <br /> Click and drag to reorder.
            </p>
            {images.length > 0 && (
              <div className="cards-wrapper">
                {images.map((image, index) => {
                  return image.ModelsImagesId ? (
                    <ModelImagesCard
                      images={home.ModelImages}
                      index={index}
                      moveCard={moveCard}
                      key="model"
                      home={home}
                      rooms={availableRooms}
                    />
                  ) : (
                    <InventoryImageCard
                      index={index}
                      image={image}
                      deleteFunction={deleteFunction}
                      saveFunction={saveDetailsFunction}
                      moveCard={moveCard}
                      key={image.InventoryImagesId}
                      rooms={availableRooms}
                      isUpdating={isUpdating}
                      isLoading={isLoading}
                    />
                  );
                })}
              </div>
            )}
            {images.length < 1 && (
              <NoImagesAvailable
                message={
                  home.IsLand
                    ? 'For this land to be visible, you must upload at least one image.'
                    : home.IsUsed
                    ? 'For your home to be visible, you must upload at least two images.'
                    : 'For your home to be visible, you must upload at least a floor plan image.'
                }
              />
            )}
          </div>
        </>
      )}
      <Modal
        modalHeadline="Save changes?"
        modalBody="If you don’t save your changes, any changes you’ve made to your sale homes will be undone."
        closeCopy="DISCARD"
        saveCopy="SAVE"
        show={showModal}
        closeCallback={toggleModal}
        saveCallback={handleConfirmNavigationClick}
        discardCallback={handleDiscardNavigationClick}
      />
    </StyledImages>
  );
};

Images.propTypes = {
  /**
   * Active home object
   */
  home: PropTypes.shape({
    InventoryId: PropTypes.number,
    ModelImages: PropTypes.arrayOf(
      PropTypes.shape({
        Reference: PropTypes.string,
      })
    ),
    InventoryImages: PropTypes.arrayOf(
      PropTypes.shape({
        InventoryImagesId: PropTypes.number,
        Reference: PropTypes.string,
      })
    ),
  }),
  updateHome: PropTypes.func.isRequired,
  history: PropTypes.shape().isRequired,
  rooms: PropTypes.shape().isRequired,
};

Images.defaultProps = {
  home: undefined,
};

export default Images;
