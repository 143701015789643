import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { UserContext } from './User';
import StyledUserInfo from './UserInfo.Style';
import AutoComplete from './AutoComplete';
import { getEAppPin, isItg, isDev, isQua, isLocalDev } from '../utils';

const UserInfo = () => {
  const {
    user: {
      FirstName,
      LastName,
      error,
      AvatarImageReference,
      loading,
      IsCorporateOfficeUser,
      AuthorizedRetailSites,
      activeLotId,
    },
    setActiveLotId,
  } = useContext(UserContext);
  const name = `${FirstName || ''} ${LastName || ''}`.trim();
  if (error) {
    return (
      <StyledUserInfo>
        <Link to="/profile" className="profile-link">
          <div className="user-image">
            <img src={AvatarImageReference} alt="smiley face" />
          </div>
        </Link>
      </StyledUserInfo>
    );
  }

  const getEappBaseUrl = () => {
    if (isLocalDev() || isDev())
      return 'https://retailsites.dev.cct-pubweb.com/eapp';
    if (isItg()) return 'https://retailsites.itg.cct-pubweb.com/eapp';
    if (isQua()) return 'https://retailsites.qua.cct-pubweb.com/eapp';
    return 'http://' + AuthorizedRetailSites[activeLotId].Url + '/eapp';
  };
  return (
    <StyledUserInfo>
      <Link to="/profile" className="profile-link">
        <div className="user-image">
          <img src={AvatarImageReference} alt={`${name}`} />
        </div>
        <div className="user-text">
          <p className="user-name">{`${name}`}</p>
          {!loading && <p className="view-profile">VIEW PROFILE</p>}
        </div>
      </Link>
      {!loading &&
        !IsCorporateOfficeUser &&
        Object.keys(AuthorizedRetailSites).length && (
          <select
            onChange={event => {
              setActiveLotId(event.target.value);
            }}
            value={activeLotId}
          >
            {Object.values(AuthorizedRetailSites).map(({ LotNumber, Name }) => (
              <option key={LotNumber} value={LotNumber}>
                {Name}
              </option>
            ))}
          </select>
        )}
      {!loading &&
        IsCorporateOfficeUser &&
        Object.keys(AuthorizedRetailSites).length && (
          <AutoComplete
            defaultValue={AuthorizedRetailSites[activeLotId].Name}
            entries={Object.values(AuthorizedRetailSites)}
            onSelect={id => {
              setActiveLotId(id);
            }}
          />
        )}
      {typeof activeLotId !== 'undefined' && (
        <div className="dealer-info">
          <table>
            <tbody>
              <tr>
                <td>Lot Number</td>
                <td className="mono">{activeLotId}</td>
              </tr>
              <tr>
                <td>
                  <a
                    href={
                      getEappBaseUrl() +
                      '/' +
                      getEAppPin(activeLotId) +
                      '/auto-start'
                    }
                    target="_blank"
                  >
                    E-App Access Code
                  </a>
                </td>
                <td className="mono">
                  <a
                    href={
                      getEappBaseUrl() +
                      '/' +
                      getEAppPin(activeLotId) +
                      '/auto-start'
                    }
                    target="_blank"
                  >
                    {getEAppPin(activeLotId)}
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </StyledUserInfo>
  );
};

export default UserInfo;
