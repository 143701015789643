import React, {
  useState,
  useEffect,
  useReducer,
  useMemo,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash.isequal';
import omit from 'lodash.omit';
import { toast } from 'react-toastify';
import { sortByKey } from '../../utils';
import { SAVE_TOAST_OPTIONS, ERROR_TOAST_OPTIONS } from '../../constants';
import StyledFeatures from './Features.Style';
import ProgressBar from '../ProgressBar';
import ToastSave from '../ToastSave';
import { featuresReducer } from '../../reducers/features';
import Modal from '../Modal';
import FeatureList from './FeaturesList';

export const MAX_FEATURE_COUNT = 10;

const Features = ({ home, updateHomeFeatures, history }) => {
  const [state, dispatch] = useReducer(featuresReducer, {
    features: [...home.Features].map((feat, index) => ({
      ...feat,
      SortOrder: index + 1,
    })),
  });
  const { features, isError, error } = state;
  const originalFeatures = home.Features;
  const [newFeatureId, setNewFeatureId] = useState(-1);
  const [showModal, setShowModal] = useState(false);
  const [lastLocation, setLastLocation] = useState(null);

  if (isError) {
    toast.error(error, ERROR_TOAST_OPTIONS);
  }

  const compareFeaturesWithoutIds = (featuresListA, featuresListB) => {
    const listA = featuresListA.map(feat => omit(feat, 'InventoryFeatureId'));
    const listB = featuresListB.map(feat => omit(feat, 'InventoryFeatureId'));

    return isEqual(listA, listB);
  };

  const removeFeature = feature => {
    dispatch({
      type: 'REMOVE_FEATURE',
      payload: feature.InventoryFeatureId,
    });
  };

  const renameFeature = (name, feature) => {
    dispatch({
      type: 'EDIT_FEATURE',
      payload: { ...feature, FeatureDescription: name },
    });
  };

  const reorderFeatures = result => {
    dispatch({
      type: 'REORDER_FEATURES',
      payload: {
        startIndex: result.source.index,
        endIndex: result.destination.index,
      },
    });
  };

  const featuresChanged = useMemo(() => {
    return !compareFeaturesWithoutIds(home.Features, features);
  }, [features, home.Features]);

  const orderedFeatures = useMemo(() => {
    return features.map((item, index) => ({
      ...item,
      SortOrder: index + 1,
      InventoryId: home.InventoryId,
    })); // ensure features have sort order that matches what they see
  }, [features, home.InventoryId]);

  const saveFunction = useCallback(() => {
    updateHomeFeatures(home, orderedFeatures);
  }, [home, orderedFeatures, updateHomeFeatures]);

  const toggleModal = useCallback(
    location => {
      toast.dismiss();
      setShowModal(!showModal);
      setLastLocation(location);
    },
    [showModal]
  );

  const handleConfirmNavigationClick = () => {
    saveFunction();

    // TODO should save changes selection forward like this?
    setTimeout(() => {
      setShowModal(!showModal);
      history.push(lastLocation);
    }, 0);
  };

  const handleDiscardNavigationClick = () => {
    dispatch({ type: 'INIT_FEATURES', payload: [...originalFeatures] });
    setTimeout(() => {
      setShowModal(!showModal);
      history.push(lastLocation);
    }, 0);
  };

  const addFeature = feature => {
    if (!feature.FeatureDescription) {
      throw new Error('Missing key: FeatureDescription');
    }
    dispatch({
      type: 'ADD_FEATURE',
      payload: {
        ...feature,
        InventoryFeatureId: feature.InventoryFeatureId || newFeatureId,
      },
    });
  };

  useEffect(() => {
    const unblock = history.block(nextLocation => {
      toggleModal(nextLocation);
      return false;
    });
    if (!featuresChanged) {
      unblock();
    }
    return () => {
      unblock();
    };
  }, [featuresChanged, history, toggleModal]);

  useEffect(() => {
    // Update local features list if home's features change
    if (featuresChanged) {
      dispatch({
        type: 'INIT_FEATURES',
        payload: sortByKey(home.Features, 'SortOrder'),
      });
    } else {
      toast.dismiss('saveToast');
    }
  }, [home.Features]);

  useEffect(() => {
    if (featuresChanged && !showModal) {
      if (!toast.isActive('saveToast')) {
        toast.info(
          <ToastSave saveFunction={saveFunction} />,
          SAVE_TOAST_OPTIONS
        );
      } else {
        // rewrite save function with updated home object
        toast.update('saveToast', {
          render: <ToastSave saveFunction={saveFunction} />,
        });
      }
    } else {
      toast.dismiss('saveToast');
    }
  }, [features, showModal, featuresChanged, saveFunction]);

  return (
    <StyledFeatures>
      <h1>Add features</h1>
      <p className="description">
        Add, edit, delete and sort features (limit 10) listed on the detail
        pages for your homes.
        <br />
        Do not use all caps.
      </p>
      <ProgressBar max={MAX_FEATURE_COUNT} current={features.length} />
      <FeatureList
        features={orderedFeatures}
        addFeature={addFeature}
        newFeatureId={newFeatureId}
        setNewFeatureId={setNewFeatureId}
        reorderFeatures={reorderFeatures}
        removeFeature={removeFeature}
        renameFeature={renameFeature}
      />
      <Modal
        modalHeadline="Save changes?"
        modalBody="If you don’t save your changes, any changes you’ve made to your sale homes will be undone."
        closeCopy="DISCARD"
        saveCopy="SAVE"
        show={showModal}
        closeCallback={toggleModal}
        saveCallback={handleConfirmNavigationClick}
        discardCallback={handleDiscardNavigationClick}
      />
    </StyledFeatures>
  );
};

Features.propTypes = {
  home: PropTypes.shape({
    Features: PropTypes.arrayOf(
      PropTypes.shape({
        InventoryFeatureId: PropTypes.number.isRequired,
      })
    ),
  }).isRequired,
  updateHomeFeatures: PropTypes.func.isRequired,
  history: PropTypes.shape().isRequired,
};

export default Features;
